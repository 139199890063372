.canvas-product-detail {
    display: flex;
    padding: 40px 15%;
}

.chosen-image-for-canvas {
    width: 48%;
    padding: 0% 2%;
}

.chosen-image-for-canvas img {
    width: 100%;
    object-fit: contain;
}

.canvas-product-detail-content {
    width: 50%;
    padding: 20px 50px;
}

.product-name {
    font-size: 35px;
    font-family: 'Suite-medium';
    margin-bottom: 20px;
}

.product-price {
    font-size: 25px;
    font-family: 'Suite-regular';
    margin-bottom: 20px;
}
.product-info {
    line-height: 1.3;
    font-family: light;
    font-family: 'Suite-regular';
}

.highlighted-text {
    font-family: 'Suite-bold';
    font-size: 20px;
    display: inline;
    box-shadow: inset 0 -15px 0 #d9fcdb;
}

.order-instruction {
    font-family: 'Suite-regular';
    font-size: 17px;
    margin-top: 20px;
    line-height: 1.5;
}
.order-buttons {
    display: flex;
    align-items: center;
}

.product-detail-title {
    font-family: 'Suite-bold';
    font-size: 32px;
    text-align: center;
    border-bottom: 2px solid black;
    width: 80%;
    margin: 0 auto 60px auto;
    padding-bottom: 14px;
}

.example-canvas {
    width: 512px;
    height: 512px;
    margin: auto;
}

.example-canvas img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .canvas-product-detail {
        flex-direction: column;
        padding: 5px;
    }

    .chosen-image-for-canvas {
        width: 100%;
        padding: 0;
    }

    .canvas-product-detail-content {
        width: 100%;
        padding: 0;
        margin-top: 10px;
    }

    .product-name {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .product-price {
        font-size: 22px;
    }

    .product-info {
        line-height: 1.3;
        font-size: 18px;
    }

    .highlighted-text {
        font-size: 22px;
    }

    .order-instruction {
        font-family: 'Suite-regular';
        font-size: 18px;
    }

    .order-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
    }

    .product-detail-title {
        width: 98%;
        margin: 30px auto 30px auto;
        font-size: 28px;
    }

    .example-canvas {
        width: 100%;
        height: 100%;
        margin: auto;
    }
}
