.footer-container {
    width: 100%;
    margin: 20px auto;
    font-size: 12px;
}

@media screen and (max-width: 500px) {
    .footer-container {
        width: 95%;
    }
}
