.crop-modal {
    position: fixed;
    /* background-color: rgba(255, 255, 255, 0.9); */
    top: 0;
    left: 0;
    z-index: 18;
    width: calc(100% - 10px);
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 5px;
}

.modal-background {
    background-image: linear-gradient(to top, #cfd9df9b 0%, #e2ebf0a5 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.modal-buttons button {
    margin: 0 15px;
}

.modal-result-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modal-banner {
    margin-bottom: 20px;
    min-width: 500px;
    max-width: 540px;
    min-height: 270px;
    width: fit-content;
    height: fit-content;
    max-width: 650px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid black;
}

.desktop-download-button {
    display: block;
}

@media only screen and (max-width: 768px) {
    .modal-banner {
        min-width: calc(100% - 40px);
        max-width: calc(100% - 40px);
        min-height: 250px;
    }
    .banner {
        min-width: calc(100% - 40px);
        max-width: calc(100% - 40px);
        min-height: 250px;
    }
    .banner-content {
        margin: 20px 5px;
    }

    .desktop-download-button {
        display: none;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1440px) {
    .banner-content {
        margin: 20px 10px;
    }

    .desktop-download-button {
        display: none;
    }
}
