body {
    margin: 0;
}

button {
    background: linear-gradient(90deg, #f2d7c0, #c2dbf0);
    border: none;
    padding: 0.8rem 2.2rem;
    font-size: 2rem;
    border-radius: 1.9rem;
    color: white;
    font-family: 'Suite-bold';
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
}

button:hover {
    cursor: pointer;
}

button:disabled {
    background: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
}

li {
    font-size: 16px;
    line-height: 1.5;
}

/* 모바일 뷰 */
@media only screen and (max-width: 768px) {
    button {
        padding: 0.6rem 2rem;
        font-size: 1.5rem;
    }
}

@font-face {
    font-family: 'Suite-bold';
    src: url(./assets/fonts/SUITE-Bold.otf);
}

@font-face {
    font-family: 'Suite-medium';
    src: url(./assets/fonts/SUITE-Medium.otf);
}

@font-face {
    font-family: 'Suite-regular';
    src: url(./assets/fonts/SUITE-Regular.otf);
}

@font-face {
    font-family: 'Suite-light';
    src: url(./assets/fonts/SUITE-Light.otf);
}
