.loading-background {
    background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    width: 100vw;
    height: 100vh;
}

.loading-container {
    position: absolute;
    top: 50%;
    left: 40%;
    margin-left: 10%;
    transform: translate3d(-50%, -50%, 0);
}

.circle {
    width: 24px;
    height: 24px;
    background: #3ac;
    border-radius: 100%;
    display: inline-block;
    animation: slide 1s infinite;
}

@keyframes anim {
    0% {
        opacity: 0;
        width: 0vmin;
        height: 0vmin;
    }
    10% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
        width: 100vmin;
        height: 100vmin;
    }
}

.circle:nth-child(1) {
    animation-delay: calc(0.1s * 1);
    background: #eea2a2;
}
.circle:nth-child(2) {
    animation-delay: calc(0.1s * 2);
    background: #bbc1bf;
}
.circle:nth-child(3) {
    animation-delay: calc(0.1s * 3);
    background: #57c6e1;
}
.circle:nth-child(4) {
    animation-delay: calc(0.1s * 4);
    background: #b49fda;
}
.circle:nth-child(5) {
    animation-delay: calc(0.1s * 5);
    background: #7ac5d8;
}

@keyframes slide {
    0% {
        transform: scale(1);
    }

    50% {
        opacity: 0.3;
        transform: scale(2);
    }

    100% {
        transform: scale(1);
    }
}
