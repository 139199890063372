#upload-button {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: #f2f2f2;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: 'Suite-bold';
    font-size: 40px;
}

#upload-photos {
    display: none;
}
#upload-button:hover {
    cursor: pointer;
}

.app {
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fefcfb;
}

.website-title {
    font-family: 'Suite-bold';
    font-size: 48px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 10px;
}
.website-title:hover {
    cursor: pointer;
}
.page-subtitle {
    font-family: 'Suite-medium';
    font-size: 32px;
    color: #000;
}

.upload-image-preview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.preview-image {
    width: 18%;
    height: 18%;
    margin: 6px;
    border-radius: 15px;
}

.page-content {
    display: flex;
    margin-top: 50px;
}

.table-title {
    font-family: 'Suite-medium';
    font-size: 25px;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    text-align: left;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.input-info span {
    font-family: 'Suite-medium';
    font-size: 12px;
    color: #707070;
}
.input-field {
    margin-bottom: 15px;
}

.custom-input-text {
    width: 90%;
    border: 1px solid black;
    height: 30px;
    border-radius: 13px;
    margin-top: 5px;
    padding: 0 16px;
    font-size: 15px;
    font-family: 'Suite-medium';
}

.custom-input-text:focus {
    outline: none;
}

.custom-select {
    margin-top: 5px;
    width: 285px;
    height: 30px;
    padding: 0.2em 0.5em;
    border: 1px solid black;
    font-family: 'Suite-medium';
    border-radius: 0px;
}

.custom-input-checkbox {
    margin-top: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 0px;
}

input#agree-checkbox {
    display: none;
}

input#agree-checkbox + label {
    cursor: pointer;
}

.checkbox-label {
    font-family: 'Suite-medium';
    font-size: 16px;
}

input#agree-checkbox + label > span {
    vertical-align: middle;
    padding-left: 5px;
}

input#agree-checkbox + label:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    vertical-align: middle;
    margin-right: 10px;
}

/* label:before에 체크 된 상태 CSS */
input#agree-checkbox:checked + label:before {
    content: '✔';
    background-color: #deeeff;
    text-align: center;
}

.center-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.justify-content-space-between {
    justify-content: space-between;
    display: flex;
    margin-bottom: 12px;
}

.info-title,
.info-info {
    font-family: 'Suite-regular';
    font-weight: normal;
    font-size: 18px;
}

.orange-info-title,
.orange-info-info {
    font-family: 'Suite-bold';
    font-size: 20px;
    color: #ff7b07;
}

.payment-success-check {
    margin: 30px 0;
    text-align: center;
}

.payment-success-check img {
    width: 100px;
    height: 100px;
}

.payment-success-body {
    font-size: 17px;
    font-family: 'Suite-medium';
    font-weight: medium;
    padding-bottom: 20px;
    line-height: 1.5;
}

.result-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.result-images img {
    width: 20%;
    height: 20%;
    margin: 8px;
}

.result-image-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.mobile-download-guide {
    display: none;
    text-align: center;
    margin-top: 20px;
    font-family: 'Suite-bold';
    font-size: 18px;
    font-weight: bold;
}

.pc-download-guide {
    text-align: center;
    margin-top: 20px;
    font-family: 'Suite-bold';
    font-size: 18px;
    font-weight: bold;
}

.desktop-download-button {
    display: block;
}

.landing-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.landing-block {
    width: 50%;

    justify-content: center;
    flex-direction: column;
    /* max-height: 1026px; */
    /* min-height: 1026px; */
}

.landing-image {
    max-width: 672px;
}

.landing-image img {
    width: 100%;
    height: 100%;
}

.intro-video-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    padding: 80px 0;
}

.landing-video {
    width: 560px;
    height: 315px;
    display: flex;
    margin-top: 30px;
}

.instruction-images {
    display: flex;
}

.instruction-image {
    width: 45%;
    margin-right: 20px;
}

.instruction-image img {
    width: 100%;
}

.crop-image {
    margin-left: 20px;
    margin-top: 10px;
}

.get-started-button {
    margin: 50px 0;
}

.colored-bold {
    color: #ff7b07;
    font-size: 40px;
    font-family: 'Suite-bold';
    font-weight: bold;
}

.light {
    color: black;
    font-family: 'Suite-regular';
    font-weight: normal;
    font-size: 28px;
    line-height: 1.5;
}

.bold {
    color: black;
    font-family: 'Suite-bold';
    font-weight: Bold;
    font-size: 32px;
}

.white-background {
    width: 100%;
    background-color: whitesmoke;
}

.block {
    width: 100%;
    margin: 80px auto;
    text-align: center;
}

.mid-title {
    font-size: 28px;
    font-family: 'Suite-bold';
    color: black;
    text-align: center;
    display: flex;
    flex-basis: 100%;
    justify-content: center;
}

.image-examples {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80vw;
    margin: auto;
}

.example {
    margin: 30px 30px 0 0;
}
.example img {
    max-width: 450px;
    margin-top: 5px;
}
.example-explain {
    font-size: 15px;
    font-family: 'Suite-medium';
    text-align: center;
    margin: 30px 0;
}
.price-instruction {
    font-size: 20px;
    font-family: 'Suite-medium';
    margin-top: 30px;
    text-align: center;
}

.canvas-info {
    margin-left: 30px;
}

.kind-buttons {
    display: flex;
}

.white-button {
    border-radius: 30px;
    font-size: 20px;
    font-weight: 600;
    border: 1px solid black;
    margin-right: 20px;
    padding: 8px 28px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
}

.white-button:hover {
    cursor: pointer;
}

.border-padding {
    padding: 0;
}

.right-align-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

select {
    padding: 5px;
    margin-top: 5px;
    font-family: 'Suite-regular';
}

/* 모바일 뷰 */
@media only screen and (max-width: 768px) {
    .canvas-info {
        margin-left: 0px;
        margin-top: 30px;
    }

    .mobile-download-guide {
        display: block;
    }

    .pc-download-guide {
        display: none;
    }

    .white-border {
        background-image: url('/src/assets/images/white-border/white-border-thin.png');
    }
    .purple-border {
        background-image: url('/src/assets/images/purple-border/purple-border-thin.png');
    }

    .border-padding {
        padding: 5px;
    }

    .website-title {
        font-size: 30px;
    }

    .page-subtitle {
        font-size: 20px;
    }
    .page-content {
        display: block;
    }

    .preview-image {
        width: 29%;
        height: 29%;
        border-radius: 15px;
    }

    .payment-success-body {
        font-size: 15px;
    }

    .result-images img {
        width: 47%;
        height: 47%;
        margin: 3px;
    }
    .desktop-download-button {
        display: none;
    }

    .custom-input-text {
        width: 88%;
    }

    .landing-content {
        padding: 10px;
    }

    .landing-block {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-height: none;
        min-height: 100%;
        margin: 20px 0;
    }

    .landing-image img {
        width: 100%;
        height: 100%;
        margin-top: 6px;
    }

    .intro-video-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .landing-video {
        width: 100%;
        height: auto;
        display: flex;
        min-height: 300px;
    }

    .instruction-images {
        display: block;
    }

    .instruction-image {
        width: 97%;
        margin-right: 0px;
        margin-top: 20px;
    }

    .crop-image {
        margin-left: 5px;
        margin-top: 5px;
    }

    .get-started-button {
        font-size: 20px;
        margin: 30px 0;
    }

    .colored-bold {
        font-size: 30px;
    }

    .light {
        font-size: 20px;
    }

    .bold {
        font-size: 20px;
    }

    .mid-title {
        font-size: 20px;
        display: flex;
        justify-content: center;
        flex-basis: 100%;
    }
    .example img {
        width: 100%;
    }
    .example {
        margin-right: 0px;
    }

    .image-examples {
        width: 90vw;
    }
    .block {
        width: 90vw;
        margin: 40px auto;
    }

    .input-field {
        margin-bottom: 20px;
    }
}

/* 태블릿 뷰 */
@media only screen and (min-width: 769px) and (max-width: 1440px) {
    .canvas-info {
        width: 80%;
        margin: 30px auto 0 auto;
    }

    .white-border {
        background-image: url('/src/assets/images/white-border/white-border-middle.png');
    }

    .purple-border {
        background-image: url('/src/assets/images/purple-border/purple-border-middle.png');
    }

    .border-padding {
        padding: 5px;
    }
    .page-content {
        flex-wrap: wrap;
    }
    .result-images img {
        width: 20%;
        height: 20%;
        margin: 8px;
    }

    .mobile-download-guide {
        display: block;
    }

    .pc-download-guide {
        display: none;
    }

    .desktop-download-button {
        display: none;
    }
    .landing-content {
        padding: 10px;
    }

    .landing-block {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-height: none;
        min-height: 100%;
        margin: 20px auto 0 auto;
        max-width: 768px;
    }

    .landing-image img {
        width: 100%;
        height: 100%;
        max-width: 768px;
        margin: 6px auto 0 auto;
    }

    .get-started-button {
        font-size: 28px;
        margin: 30px 0;
    }
}
