.banner {
    margin-left: 20px;
    margin-bottom: 20px;
    min-width: 500px;
    max-width: 540px;
    min-height: 270px;
    width: fit-content;
    height: fit-content;
    max-width: 650px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid black;
}

.banner-title {
    background-color: #efefef;
    height: 40px;
    margin-top: 20px;
    font-size: 20px;
    font-family: 'Suite-medium';
    padding: 0px;
}

.banner-title p {
    padding-top: 7px;
    padding-left: 10px;
}

.banner-content {
    margin: 20px;
}

@media only screen and (max-width: 768px) {
    .banner {
        min-width: 100%;
        /* max-width: calc(100% - 40px); */
        min-height: 250px;
        margin-left: 0;
    }
    .banner-content {
        margin: 20px 5px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1440px) {
    .banner-content {
        margin: 20px 10px;
    }
}
